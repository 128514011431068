import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { detailed_list } from "./detailed_list_updated";
import { firestore } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCalendarCheck, FaCheckCircle, FaChevronDown, FaCoins, FaMapMarked, FaPhoneAlt, FaStarHalfAlt, FaTags, FaTaxi, FaTrain, FaWalking } from "react-icons/fa";

const RoomDetails = () => {
  const { accommodationId } = useParams();
  const roomTypesRef = useRef(null);
  const [roomDetails, setRoomDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        // First check in local data
        const localRoom = detailed_list.find((room) => room.accommodationId === accommodationId);

        if (localRoom) {
          setRoomDetails(localRoom);
          setLoading(false);
          return;
        }

        // If not found in local data, check Firebase
        const docRef = doc(firestore, 'accommodations', accommodationId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const firebaseData = docSnap.data();
          // Transform Firebase data to match the expected format
          const transformedData = {
            accommodationId: docSnap.id,
            roomName: firebaseData.title || firebaseData.roomName,
            city: firebaseData.city,
            location: firebaseData.location,
            cost: `£${firebaseData.cost}/month`,
            distance: `${firebaseData.distance} mi from ${firebaseData.city}` || "Distance information not available",
            walkingDistance: firebaseData.walkingDistance || "N/A",
            trainDistance: firebaseData.trainDistance || "N/A",
            drivingDistance: firebaseData.drivingDistance || "N/A",
            tags: [...(firebaseData.amenities || [])],
            offersAndDetails: firebaseData.offersAndDetails || [],
            description: firebaseData.description || "",
            room_types: firebaseData.roomTypes || [],
            images: firebaseData.images || [],
          };
          setRoomDetails(transformedData);
        } else {
          console.log("No such document!");
          setRoomDetails(null);
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
        setRoomDetails(null);
      } finally {
        setLoading(false);
      }
    };

    fetchRoomDetails();
  }, [accommodationId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center text-gray-100 h-screen">
        Loading...
      </div>
    );
  }

  if (!roomDetails) {
    return <div className="flex items-center justify-center text-gray-100 h-screen">Room details not found.</div>;
  }

  const {
    roomName,
    city,
    location,
    cost,
    distance,
    walkingDistance,
    trainDistance,
    drivingDistance,
    tags,
    offersAndDetails,
    description,
    room_types,
    images,
  } = roomDetails;

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  const handleEnquire = () => {
    toast(
      <span>
        Please contact <a href="mailto:hello@worldlynk.co.uk" style={{ color: 'blue' }}>hello@worldlynk.co.uk</a> for more assistance.
      </span>
    );
  };

  const scrollToRoomTypes = () => {
    if (roomTypesRef.current) {
      roomTypesRef.current.scrollIntoView({ behavior: 'smooth' }); // Step 2: Scroll to the "Room Types" section
    }
  };
  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <div className="container mx-auto py-8 px-4">
        <div className="grid grid-cols-12 gap-8">
          {/* Left Section: Image Slider and Content */}
          <div className="lg:col-span-8 col-span-12">
            <div className="slider_arrows">
              <Slider {...sliderSettings} className="relative group">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Room Image ${index + 1}`}
                    className="w-full h-96 object-cover rounded-lg"
                  />
                ))}
              </Slider>
            </div>

            {/* Room Name and Address */}
            <div className="flex justify-content-between items-center">
              <div className="mt-4">
                <h1 className="text-3xl font-bold">{roomName}</h1>
                <p className="text-lg text-gray-300">{location}</p>
                {/* Distance Section */}
                <div className="flex items-center">
                  <div>
                    <FaMapMarked className="w-75 h-10"></FaMapMarked>
                  </div>
                  <div>
                    <small className="font-bold">{distance}</small>
                    <div className="flex items-center text-gray-300">
                      <small><FaWalking className="inline"></FaWalking> {walkingDistance}</small>
                      <small className="mx-2"><FaTrain className="inline"></FaTrain> {trainDistance}</small>
                      <small><FaTaxi className="inline"></FaTaxi> {drivingDistance}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="font-bold text-xl">{cost}</p>
              </div>
            </div>
            {/* Tags Section */}
            <div className="mt-4">
              <div className="flex flex-wrap gap-2 mt-2">
                {tags.map((tag, index) => (
                  <span
                    key={index}
                    className="border-2 text-white py-1 px-3 rounded-full text-sm border-orange-500"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>



            {/* About the Property Section */}
            <div className="mt-8">
              <h2 className="text-2xl font-bold">About the Property</h2>
              <p className="text-sm text-gray-300 mt-2">{description}</p>
            </div>

            {/* Room Types Section */}
            {/* {room_types && room_types.length > 0 ? (
              <div className="hidden lg:block mt-8" ref={roomTypesRef}>
                <h2 className="text-2xl font-bold text-gray-100 mb-6">Room Types</h2>
                <div className="space-y-6">
                  {room_types.map((room, index) => (
                    <div
                      key={index}
                      className="bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-all cursor-pointer overflow-hidden" onClick={handleEnquire}
                    >
                      <div className="flex flex-col md:flex-row"> */}
            {/* Image Section */}
            {/* <div className="w-full md:w-1/4 h-48 md:h-auto bg-gray-700 flex items-center justify-center">
                          <img
                            src={room.image_url || "https://ik.imgkit.net/3vlqs5axxjf/TW/ik-seo/dist/images/no-image/Hotel-dell%27Ancora.jpg?tr=w-360%2Ch-216%2Cfo-auto"}
                            alt={room.room_type_name}
                            className="w-full h-full object-cover"
                          />
                        </div> */}

            {/* Details Section */}
            {/* <div className="flex-1 p-6">
                          <h4 className="text-lg font-semibold text-white mb-2">
                            {room.room_type_name}
                          </h4>
                          <p className="text-green-400 font-medium mb-2">
                            {room.availability_date}
                          </p>
                          <p className="text-gray-400 mb-2">
                            Starting Price:
                            <span className="ms-1 font-semibold text-gray-300">
                              {room.starting_price}
                            </span>
                          </p> */}

            {/* Features as Tags
                          <div className="flex flex-wrap items-center gap-2">
                            {room.features.map((feature, idx) => (
                              <span
                                key={idx}
                                className="bg-gray-700 text-gray-300 py-1 px-3 rounded-full text-sm"
                              >
                                {feature}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>) : (
              <div className="mt-8">
                <h2 className="text-2xl font-bold text-gray-100 mb-6">Room Types</h2>
                <p className="text-gray-300">No room types available for this room.</p>
              </div>
            )} */}
          </div>


          {/* Right Section: Sticky UI */}
          <div className="lg:col-span-4 col-span-12">
            <div className="lg:sticky lg:top-8 d-flex gap-2">
              <button className="bg-orange-500 hover:bg-orange-600 text-white py-2 rounded-lg w-50" onClick={scrollToRoomTypes}>
                View Rooms
              </button>
              <button className="bg-orange-600 hover:bg-orange-700 text-white py-2 rounded-lg w-50" onClick={() => handleEnquire()}>
                Enquire Now
              </button>
            </div>
            <div className="sticky top-24 space-y-4 mt-3">
              {/* Offers Section */}
              <div className="bg-white p-3 rounded-4 shadow-md">
                <h3 className="text-2xl font-bold text-gray-800 mb-6">Offers & Details</h3>
                <div className="space-y-4">
                  {/* Full-width cards for the first two offers */}
                  {offersAndDetails.slice(0, 2).map((offer, index) => (
                    <div
                      key={index}
                      className="flex items-center"
                    >
                      <div className="flex-shrink-0 w-9 h-9 flex items-center justify-center bg-orange-500 text-white rounded-full">
                        <FaTags className="text-lg" />
                      </div>
                      <div className="ml-2">
                        <p className="text-sm text-gray-800 font-medium m-0">{offer}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  {/* Two-column grid for the rest of the offers */}
                  {offersAndDetails.slice(2).map((offer, index) => (
                    <div
                      key={index}
                      className="flex items-center"
                    >
                      <div className="flex-shrink-0 w-9 h-9 flex items-center justify-center bg-orange-500 text-white rounded-full">
                        <FaTags className="text-lg" />
                      </div>
                      <div className="ml-2">
                        <p className="text-sm text-gray-800 font-medium m-0">{offer}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>




              {/* <div className=""> */}
              {/* Instant Booking */}
              {/* <div className="border-b border-gray-700">
                  <input type="checkbox" id="item-1" className="peer hidden" />
                  <label
                    htmlFor="item-1"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaCalendarCheck className="me-2"></FaCalendarCheck>Instant Booking</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>Instant booking allows you to quickly book the property by paying the amount.</p>
                  </div>
                </div> */}

              {/* Lowest Price Guaranteed */}
              {/* <div className="border-b border-gray-700">
                  <input type="checkbox" id="item-2" className="peer hidden" />
                  <label
                    htmlFor="item-2"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaCoins className="me-2"></FaCoins>Lowest Price Guaranteed</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>We guarantee to match the price of your accommodation if you find an identical offer on another.</p>
                  </div>
                </div> */}

              {/* Verified Properties */}
              {/* <div className="border-b border-gray-700">
                  <input type="checkbox" id="item-3" className="peer hidden" />
                  <label
                    htmlFor="item-3"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaCheckCircle className="me-2"></FaCheckCircle>Verified Properties</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>We guarantee that what you see on our website is exactly what you'll get.</p>
                  </div>
                </div> */}

              {/* 24x7 Personal Assistance */}
              {/* <div className="border-b border-gray-700">
                  <input type="checkbox" id="item-4" className="peer hidden" />
                  <label
                    htmlFor="item-4"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaPhoneAlt className="me-2"></FaPhoneAlt>24x7 Personal Assistance</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>For any doubts or queries, a quick call is all it takes - we're here to assist you promptly.</p>
                  </div>
                </div> */}

              {/* 5.8K+ Reviews */}
              {/* <div>
                  <input type="checkbox" id="item-5" className="peer hidden" />
                  <label
                    htmlFor="item-5"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaStarHalfAlt className="me-2"></FaStarHalfAlt>5.8K+ Reviews</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>We've earned an excellent rating from over 5,800+ students for our outstanding services.</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
