import React, { useState, useEffect } from 'react';

const ImageCompressor = ({ src, alt, className }) => {
  const [compressedSrc, setCompressedSrc] = useState(null); // For storing compressed image

  // Function to compress the image before rendering it
  const compressImage = async (img) => {
    const width = img.naturalWidth;
    const height = img.naturalHeight;

    // Create a canvas to compress the image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    // Draw the image onto the canvas
    ctx.drawImage(img, 0, 0, width, height);

    // Compress the image into a JPEG with 60% quality
    const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.6);

    // Set the compressed image source
    setCompressedSrc(compressedDataUrl);
  };

  // Effect to handle image compression when the component mounts
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.alt = alt;

    // Wait until the image is fully loaded (via onload) to get the dimensions
    img.onload = () => {
      compressImage(img);
    };
  }, [src, alt]);

  // If the image is not ready yet, render nothing
  if (!compressedSrc) {
    return null; // Or you can return a placeholder like an empty div
  }

  // Render the compressed image once ready
  return (
    <img
      src={compressedSrc} // Use the compressed image source
      alt={alt}
      className={className} // Apply the provided className
    />
  );
};

export default ImageCompressor;
