import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../../firebase';
import { collection, getDocs } from 'firebase/firestore';

const RecommendedUniversities = () => {
    const navigate = useNavigate();
    const [universities, setUniversities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const universitiesPerPage = 8;

    useEffect(() => {
        const fetchUniversities = async () => {
            try {
                const universitiesRef = collection(firestore, 'universities');
                const snapshot = await getDocs(universitiesRef);
                const universitiesList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setUniversities(universitiesList);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching universities:', err);
                setError('Failed to load universities');
                setLoading(false);
            }
        };

        fetchUniversities();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="text-white">Loading universities...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="text-red-500">{error}</div>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 py-1">
            {universities.slice((currentPage - 1) * universitiesPerPage, currentPage * universitiesPerPage).map(
                (university, index) => (
                    <div
                        key={index}
                        className="bg-gray-800 rounded-lg overflow-hidden cursor-pointer shadow-lg hover:scale-105 transition duration-300"
                        onClick={() => navigate(`/user-dashboard/universities/${university.id}`)}
                    >
                        <div
                            className="relative h-48"
                            style={{
                                backgroundImage: `url(${university.background_image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div className='flex h-20 w-20 items-center bg-white bottom-4 left-4  content-center absolute rounded-lg'>
                                <img
                                    src={university.logo_image}
                                    className="max-w-full h-auto "
                                />
                            </div>

                        </div>
                        <div className="p-4">
                            <h3 className="text-xl font-semibold mb-2 text-white">{university.university_name}</h3>
                            <p className="text-sm text-gray-300 mb-2">{university.location}</p>

                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default RecommendedUniversities;