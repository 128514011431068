import React from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend)

const UniversityDashboard = () => {
  const barData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Student Inquiries',
        data: [45, 52, 38, 60, 48, 70],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  }

  const lineData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Student Engagement',
        data: [120, 145, 160, 175, 190, 210],
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  }

  return (
    <div className="w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-white mb-8 px-4">University Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4">
        {/* Student Inquiries Analysis Section */}
        <section className="mb-8 bg-gray-800 rounded-xl p-6">
          <h2 className="text-2xl font-bold text-white mb-6 px-2">Student Inquiries Analysis</h2>
          <div className="bg-gray-700 p-6 rounded-lg space-y-4">
            <div className="mb-6">
              <Bar data={barData} />
            </div>
            <div className="space-y-3">
              <p className="text-white text-lg">Total Inquiries: <span className="font-semibold">313</span></p>
              <p className="text-white text-lg">Responded Inquiries: <span className="font-semibold">290</span></p>
              <p className="text-white text-lg">Pending Responses: <span className="font-semibold">23</span></p>
              <p className="text-white text-lg">Average Response Time: <span className="font-semibold">2 hours</span></p>
            </div>
          </div>
        </section>
        
        {/* Engagement Trends Section */}
        <section className="mb-8 bg-gray-800 rounded-xl p-6">
          <h2 className="text-2xl font-bold text-white mb-6 px-2">Student Engagement Trends</h2>
          <div className="bg-gray-700 p-6 rounded-lg">
            <div className="mb-6">
              <Line data={lineData} />
            </div>
            <p className="text-white text-lg mt-4">Monthly Student Engagement Growth</p>
          </div>
        </section>
        
        {/* Ambassador Activities Section */}
        <section className="mb-8 bg-gray-800 rounded-xl p-6">
          <h2 className="text-2xl font-bold text-white mb-6 px-2">Ambassador Activities</h2>
          <div className="bg-gray-700 p-6 rounded-lg space-y-3">
            <p className="text-white text-lg">Events Organized: <span className="font-semibold">12</span></p>
            <p className="text-white text-lg">Virtual Sessions: <span className="font-semibold">25</span></p>
            <p className="text-white text-lg">Blog Posts Written: <span className="font-semibold">8</span></p>
            <p className="text-white text-lg">Resource Materials Created: <span className="font-semibold">15</span></p>
          </div>
        </section>
        
        {/* Impact Metrics Section */}
        <section className="bg-gray-800 rounded-xl p-6">
          <h2 className="text-2xl font-bold text-white mb-6 px-2">Impact Metrics</h2>
          <div className="bg-gray-700 p-6 rounded-lg space-y-3">
            <p className="text-white text-lg">Student Satisfaction: <span className="font-semibold">92%</span></p>
            <p className="text-white text-lg">Query Resolution Rate: <span className="font-semibold">95%</span></p>
            <p className="text-white text-lg">Student Conversion Rate: <span className="font-semibold">68%</span></p>
            <p className="text-white text-lg">Active Students Engaged: <span className="font-semibold">450</span></p>
          </div>
        </section>
      </div>
    </div>
  )
}

export default UniversityDashboard