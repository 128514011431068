import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UniData from './data/no_of_uni_updated'; // Import updated university data
import uni from '../../../assets/img/uni.jpg'; // Fix import statement
import RecommendedUniversities from './RecommendedUniversities';
const University = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const universitiesPerPage = 8; // Number of universities per page
  const totalPages = Math.ceil(Object.keys(UniData).length / universitiesPerPage);
  const [searchTerm, setSearchTerm] = useState('');

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Filtered universities based on search term
  const filteredUniversities = Object.values(UniData).filter(university =>
    university.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-gray-900 min-h-screen">
      
        <div className="relative bg-gray-800 py-12 rounded-4 mx-2 bg-cover bg-center" style={{ backgroundImage: `url(${uni})`, backgroundBlendMode: 'darken', backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
             <div className="absolute top-4 right-4 z-20">
             </div>
             <div className="relative z-10 container mx-auto px-4">
           <h1 className="text-4xl sm:text-5xl font-bold text-white mb-4">Find a perfect University</h1>
           <p className="text-xl text-gray-100">    Discover the rich history and academic excellence of institutions like Oxford and Cambridge. The UK offers a diverse range of educational experiences.
           </p>
             </div>
           </div>

           <div className="container mx-auto py-4 px-4">
        <div className="mb-6">
          <div className="flex flex-col sm:flex-row items-center mb-6 space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="flex-grow w-full sm:w-auto">
              <div className="flex items-center w-full border-2 border-orange-600 rounded-md bg-transparent">
                <span className="pl-3 text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-4.35-4.35m0 0a7 7 0 111.4-1.4m-1.4 1.4L16.65 16.65z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search universities..."
                  className="bg-transparent text-gray-100 py-2.5 pl-3 pr-3 w-full focus:outline-none"
                />
              </div>
            </div>
            <div className="flex space-x-2 w-full sm:w-auto">
              <button className="bg-gray-50 text-black rounded-md font-bold px-3 py-2 text-sm sm:text-base hover:bg-gray-100 transition duration-200 w-full sm:w-auto">
                Clear
              </button>
              <button className="bg-orange-600 text-white rounded-md font-bold px-3 py-2 text-sm sm:text-base hover:bg-orange-700 transition duration-200 w-full sm:w-auto">
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="px-2">
          {searchTerm ? (
            <div className="mt-8">
              <h2 className="text-2xl font-bold text-white mb-4">Search Results</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {filteredUniversities.map((university, index) => (
                  <div
                    key={index}
                    className="bg-gray-800 rounded-lg overflow-hidden cursor-pointer shadow-lg hover:scale-105 transition duration-300"
                    onClick={() => navigate(`/user-dashboard/universities/${university.id}`)}
                  >
                    <div
                      className="relative h-48"
                      style={{
                        backgroundImage: `url(${university.background_image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    >
                      <div className='flex h-20 w-20 items-center bg-white bottom-4 left-4 content-center absolute rounded-lg'>
                        <img
                          src={university.logo_image}
                          className="max-w-full h-auto"
                        />
                      </div>
                    </div>
                    <div className="p-4">
                      <h3 className="text-xl font-semibold mb-2 text-white">{university.name}</h3>
                      <p className="text-sm text-gray-300 mb-2">{university.location}</p>
                    </div>
                  </div>
                ))}
              </div>
              {filteredUniversities.length === 0 && (
                <p className="text-gray-400 text-center">No universities found matching your search.</p>
              )}
            </div>
          ) : (
            <>
              <h2 className="text-2xl font-bold text-white mb-4">Recommended Universities</h2>
              <RecommendedUniversities />

              <div className="mt-8">
                <h2 className="text-2xl font-bold text-white mb-4">Explore More Universities</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                  {filteredUniversities.slice((currentPage - 1) * universitiesPerPage, currentPage * universitiesPerPage).map(
                    (university, index) => (
                      <div
                        key={index}
                        className="bg-gray-800 rounded-lg overflow-hidden cursor-pointer shadow-lg hover:scale-105 transition duration-300"
                        onClick={() => navigate(`/user-dashboard/universities/${university.id}`)}
                      >
                        <div
                          className="relative h-48"
                          style={{
                            backgroundImage: `url(${university.background_image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        >
                          <div className='flex h-20 w-20 items-center bg-white bottom-4 left-4 content-center absolute rounded-lg'>
                            <img
                              src={university.logo_image}
                              className="max-w-full h-auto"
                            />
                          </div>
                        </div>
                        <div className="p-4">
                          <h3 className="text-xl font-semibold mb-2 text-white">{university.name}</h3>
                          <p className="text-sm text-gray-300 mb-2">{university.location}</p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="flex justify-center items-center space-x-4 mt-8">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
                >
                  Previous
                </button>
                <span className="text-gray-400">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default University;
