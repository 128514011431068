import React, { useEffect, useState } from 'react';
import { firestore } from '../../../firebase';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import Modal from 'react-modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Edit, Trash2 } from 'lucide-react';

Modal.setAppElement('#root'); // Required for accessibility when using Modal.

const AddCourses = () => {
  const { userData } = useAuth();
  const [universities, setUniversities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [courseData, setCourseData] = useState({
    course_name: '',
    university: '',
    location: '',
    qualification: '',
    duration: '',
    study_mode: '',
    ucas_points: '',
    type: 'Undergraduate', // Default to Undergraduate
    study_period: 'Full-time', // Default study mode
  });
  const [editIndex, setEditIndex] = useState(null);
  const [courseType, setCourseType] = useState('Undergraduate');
  const [activeCourseFilter, setActiveCourseFilter] = useState('Full-time');
  const [loadedCourses, setLoadedCourses] = useState([]);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const q = query(
          collection(firestore, 'universities'),
          where('userId', '==', userData.userId)
        );
        const querySnapshot = await getDocs(q);
        const universityList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUniversities(universityList);
        if (universityList.length > 0) {
          setLoadedCourses(universityList[0].courses[courseType][activeCourseFilter]);
        }
      } catch (error) {
        console.error('Error fetching universities:', error);
      }
    };

    fetchUniversities();
  }, [userData.userId, courseType, activeCourseFilter]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddCourse = async () => {
    setIsLoading(true);
    try {
      if (universities.length > 0) {
        const selectedUniversity = universities[0];
        const updatedCourses = { ...selectedUniversity.courses };

        if (!updatedCourses[courseData.type]) {
          updatedCourses[courseData.type] = {};
        }

        if (!updatedCourses[courseData.type][courseData.study_period]) {
          updatedCourses[courseData.type][courseData.study_period] = [];
        }

        if (editIndex !== null) {
          updatedCourses[courseData.type][courseData.study_period][editIndex] = courseData;
        } else {
          updatedCourses[courseData.type][courseData.study_period].push(courseData);
        }

        await updateDoc(doc(firestore, 'universities', selectedUniversity.id), {
          courses: updatedCourses,
        });

        toast.success('Course added successfully!');
        setCourseData({
          course_name: '',
          university: '',
          location: '',
          qualification: '',
          duration: '',
          study_mode: '',
          ucas_points: '',
          type: 'Undergraduate',
          study_period: 'Full-time',
        });
        setIsModalOpen(false);
        setEditIndex(null);
        setLoadedCourses(updatedCourses[courseType][activeCourseFilter]);
      } else {
        toast.error('No university found to add the course.');
      }
    } catch (error) {
      console.error('Error adding course:', error);
      toast.error('Error adding course. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditCourse = (type, period, index) => {
    const selectedUniversity = universities[0];
    const courseToEdit = selectedUniversity.courses[type][period][index];
    setCourseData(courseToEdit);
    setEditIndex(index);
    setIsModalOpen(true);
  };

  const handleDeleteCourse = async () => {
    setIsLoading(true);
    try {
      if (universities.length > 0) {
        const selectedUniversity = universities[0];
        const updatedCourses = { ...selectedUniversity.courses };

        updatedCourses[courseData.type][courseData.study_period].splice(editIndex, 1);

        await updateDoc(doc(firestore, 'universities', selectedUniversity.id), {
          courses: updatedCourses,
        });

        toast.success('Course deleted successfully!');
        setCourseData({
          course_name: '',
          university: '',
          location: '',
          qualification: '',
          duration: '',
          study_mode: '',
          ucas_points: '',
          type: 'Undergraduate',
          study_period: 'Full-time',
        });
        setIsModalOpen(false);
        setEditIndex(null);
        setLoadedCourses(updatedCourses[courseType][activeCourseFilter]);
      } else {
        toast.error('No university found to delete the course.');
      }
    } catch (error) {
      console.error('Error deleting course:', error);
      toast.error('Error deleting course. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const openAddCourseModal = () => {
    setCourseData({
      course_name: '',
      university: '',
      location: '',
      qualification: '',
      duration: '',
      study_mode: '',
      ucas_points: '',
      type: 'Undergraduate',
      study_period: 'Full-time',
    });
    setEditIndex(null);
    setIsModalOpen(true);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <ToastContainer />
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Available Courses</h2>
        <button
          onClick={openAddCourseModal}
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Add Course
        </button>
      </div>

      <div className="mb-6">
        <div className="flex space-x-4 mb-4">
          <button
            onClick={() => {
              setCourseType('Undergraduate');
              setLoadedCourses(universities[0].courses['Undergraduate'][activeCourseFilter]);
            }}
            className={`px-4 py-2 rounded-lg border-2 ${courseType === 'Undergraduate' ? 'bg-blue-600 text-white border-blue-600' : 'bg-gray-800 text-gray-300 border-white'
              }`}
          >
            Undergraduate
          </button>
          <button
            onClick={() => {
              setCourseType('Postgraduate');
              setLoadedCourses(universities[0].courses['Postgraduate'][activeCourseFilter]);
            }}
            className={`px-4 py-2 rounded-lg border-2 ${courseType === 'Postgraduate' ? 'bg-blue-600 text-white border-blue-600' : 'bg-gray-800 text-gray-300 border-white'
              }`}
          >
            Postgraduate
          </button>
        </div>

        <div className="flex space-x-4">
          <button
            onClick={() => {
              setActiveCourseFilter('Full-time');
              setLoadedCourses(universities[0].courses[courseType]['Full-time']);
            }}
            className={`px-4 py-2 rounded-lg border-2 ${activeCourseFilter === 'Full-time' ? 'bg-blue-600 text-white border-blue-600' : 'bg-gray-800 text-gray-300 border-white'
              }`}
          >
            Full-time
          </button>
          <button
            onClick={() => {
              setActiveCourseFilter('Part-time');
              setLoadedCourses(universities[0].courses[courseType]['Part-time']);
            }}
            className={`px-4 py-2 rounded-lg border-2 ${activeCourseFilter === 'Part-time' ? 'bg-blue-600 text-white border-blue-600' : 'bg-gray-800 text-gray-300 border-white'
              }`}
          >
            Part-time
          </button>
        </div>
      </div>

      {/* Display Filtered Courses */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {loadedCourses.length > 0 ? (
          loadedCourses.map((course, index) => (
            <div
              key={index}
              className="bg-gray-900 p-6 rounded-lg shadow-md hover:bg-gray-850 transition-all cursor-pointer relative"
            >
              <button
                onClick={() => handleEditCourse(courseType, activeCourseFilter, index)}
                className="absolute top-2 right-2 text-blue-500 hover:text-blue-400"
              >
                <Edit size={20} />
              </button>
              <h3 className="text-lg font-medium text-gray-100">{course.course_name}</h3>
              <p className="text-gray-400">{course.study_mode} - {course.duration}</p>
              <p className="text-gray-400">Location: {course.location}</p>
              <p className="text-gray-400">Qualification: {course.qualification}</p>
              <p className="text-gray-400">UCAS Points: {course.ucas_points}</p>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center">
            <div className="loader text-center py-6 text-white">
              Loading courses...
            </div>
          </div>
        )}
      </div>

      {/* Modal for adding courses */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="bg-gray-800 text-white p-6 rounded-lg max-w-4xl mx-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-bold mb-4">{editIndex !== null ? 'Edit Course' : 'Add Course'}</h2>
        <form className="space-y-4">
          {/* 2-3 fields per row */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <label className="block mb-2">Course Name</label>
              <input
                type="text"
                name="course_name"
                value={courseData.course_name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded bg-gray-700 text-white"
              />
            </div>
            <div>
              <label className="block mb-2">University</label>
              <input
                type="text"
                name="university"
                value={courseData.university}
                onChange={handleInputChange}
                className="w-full p-2 border rounded bg-gray-700 text-white"
              />
            </div>
            <div>
              <label className="block mb-2">Location</label>
              <input
                type="text"
                name="location"
                value={courseData.location}
                onChange={handleInputChange}
                className="w-full p-2 border rounded bg-gray-700 text-white"
              />
            </div>
            <div>
              <label className="block mb-2">Qualification</label>
              <input
                type="text"
                name="qualification"
                value={courseData.qualification}
                onChange={handleInputChange}
                className="w-full p-2 border rounded bg-gray-700 text-white"
              />
            </div>
            <div>
              <label className="block mb-2">Duration</label>
              <input
                type="text"
                name="duration"
                value={courseData.duration}
                onChange={handleInputChange}
                className="w-full p-2 border rounded bg-gray-700 text-white"
              />
            </div>
            <div>
              <label className="block mb-2">Study Mode</label>
              <input
                type="text"
                name="study_mode"
                value={courseData.study_mode}
                onChange={handleInputChange}
                className="w-full p-2 border rounded bg-gray-700 text-white"
              />
            </div>
            <div>
              <label className="block mb-2">UCAS Points</label>
              <input
                type="text"
                name="ucas_points"
                value={courseData.ucas_points}
                onChange={handleInputChange}
                className="w-full p-2 border rounded bg-gray-700 text-white"
              />
            </div>
            <div>
              <label className="block mb-2">Course Type</label>
              <select
                name="type"
                value={courseData.type}
                onChange={handleInputChange}
                className="w-full p-2 border rounded bg-gray-700 text-white"
              >
                <option value="Undergraduate">Undergraduate</option>
                <option value="Postgraduate">Postgraduate</option>
              </select>
            </div>
            <div>
              <label className="block mb-2">Study Period</label>
              <select
                name="study_period"
                value={courseData.study_period}
                onChange={handleInputChange}
                className="w-full p-2 border rounded bg-gray-700 text-white"
              >
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
              </select>
            </div>
          </div>
        </form>
        <div className="mt-4 flex justify-end space-x-4">
          <button
            onClick={() => setIsModalOpen(false)}
            className="bg-gray-500 text-white py-2 px-4 rounded"
          >
            Cancel
          </button>
          {editIndex !== null && (
            <button
              onClick={handleDeleteCourse}
              className="bg-red-500 text-white py-2 px-4 rounded"
              disabled={isLoading}
            >
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          )}
          <button
            onClick={handleAddCourse}
            className="bg-blue-500 text-white py-2 px-4 rounded"
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddCourses;
