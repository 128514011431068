import React, { useState, useEffect } from "react";
import {
  Building2,
  MapPin,
  Globe,
  Mail,
  Phone,
  Image,
  Plus,
  Save,
  MapPinned,
  Trash2
} from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuth } from "../../../auth/userProvider/AuthProvider";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { addDoc, collection, getDocs, query, where, updateDoc, doc } from "firebase/firestore";
import { firestore, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Custom hook for form state management
const useFormState = (initialState) => {
  const [state, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNestedChange = (index, field, e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      course_locations: prevState.course_locations.map((location, idx) =>
        idx === index ? { ...location, [field]: value } : location
      ),
    }));
  };

  const addNestedField = () => {
    setState((prevState) => ({
      ...prevState,
      course_locations: [
        ...prevState.course_locations,
        { location_name: '', location_address: '' },
      ],
    }));
  };

  const deleteNestedField = (index) => {
    setState((prevState) => ({
      ...prevState,
      course_locations: prevState.course_locations.filter(
        (_, idx) => idx !== index
      ),
    }));
  };

  return {
    state,
    handleChange,
    handleNestedChange,
    addNestedField,
    deleteNestedField,
    setState, // Add setState to the return object
  };
};

const InputField = ({ icon: Icon, label, name, ...props }) => (
  <div>
    <label
      htmlFor={name} // Ensure the for attribute matches the id
      className="block mb-2 text-sm font-medium"
    >
      {label}
    </label>
    <div className="flex items-center bg-gray-700 rounded">
      <Icon className="ml-3 text-gray-400" />
      <input
        id={name} // Ensure the id matches the for attribute of the label
        name={name}
        {...props}
        className="w-full p-3 bg-transparent text-white rounded"
      />
    </div>
  </div>
);

const UploadUniversityInfo = () => {
  const { userData } = useAuth(); // Move useAuth hook here
  const [isEditMode, setIsEditMode] = useState(false);
  const [universityId, setUniversityId] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    state: universityData,
    handleChange,
    handleNestedChange,
    addNestedField,
    deleteNestedField,
    setState,
  } = useFormState({
    university_name: '',
    location: '',
    official_website: '',
    contact_email: '',
    contact_phone: '',
    course_locations: [{ location_name: '', location_address: '' }],
    logo_url: '',
    background_image: '',
    about_us: '',
    courses:{
      "Undergraduate":{
        "Full-time": [],
        "Part-time": []
      },
      "Postgraduate":{
        "Full-time": [],
        "Part-time": []
      }
    }
  });

  useEffect(() => {
    const fetchUniversity = async () => {
      try {
        const q = query(collection(firestore, 'universities'), where('userId', '==', userData.userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const universityDoc = querySnapshot.docs[0];
          setUniversityId(universityDoc.id);
          setState(universityDoc.data());
          setIsEditMode(true);
        }
      } catch (error) {
        console.error('Error fetching university data:', error);
      }
    };

    fetchUniversity();
  }, [userData.userId, setState]);

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async (file, path) => {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const timestamp = new Date();
      const universityDataWithTimestamps = {
        ...universityData,
        updatedAt: timestamp,
        userId: userData.userId, // Use userData here
      };

      if (logoFile) {
        const logoPath = `${userData.userId}/${universityData.university_name}/logo`;
        universityDataWithTimestamps.logo_url = await uploadFile(logoFile, logoPath);
      }

      if (backgroundFile) {
        const backgroundPath = `${userData.userId}/${universityData.university_name}/background`;
        universityDataWithTimestamps.background_image = await uploadFile(backgroundFile, backgroundPath);
      }

      if (isEditMode) {
        await updateDoc(doc(firestore, 'universities', universityId), universityDataWithTimestamps);
        toast.success('University information updated successfully!');
      } else {
        universityDataWithTimestamps.createdAt = timestamp;
        await addDoc(collection(firestore, 'universities'), universityDataWithTimestamps);
        toast.success('University information saved successfully!');
      }
    } catch (error) {
      console.error('Error saving university data:', error);
      toast.error('Error saving university information');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <ToastContainer />
      <h2 className="text-3xl font-bold mb-6 flex items-center">
        <Building2 className="mr-3 text-blue-400" />
        {isEditMode ? 'Edit University Information' : 'Add University Information'}
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            icon={Building2}
            label="University Name"
            type="text"
            name="university_name"
            placeholder="University Name"
            value={universityData.university_name}
            onChange={handleChange}
          />
          <InputField
            icon={MapPin}
            label="Location"
            type="text"
            name="location"
            placeholder="Location"
            value={universityData.location}
            onChange={handleChange}
          />
          <InputField
            icon={Globe}
            label="Official Website"
            type="text"
            name="official_website"
            placeholder="Official Website"
            value={universityData.official_website}
            onChange={handleChange}
          />
          <InputField
            icon={Mail}
            label="Contact Email"
            type="email"
            name="contact_email"
            placeholder="Contact Email"
            value={universityData.contact_email}
            onChange={handleChange}
          />
          <InputField
            icon={Phone}
            label="Contact Phone"
            type="text"
            name="contact_phone"
            placeholder="Contact Phone"
            value={universityData.contact_phone}
            onChange={handleChange}
          />
        </div>

        {/* About Section */}
        <div>
          <label
            htmlFor="about-university"
            className="block mb-2 text-sm font-medium"
          >
            About University
          </label>
          <ReactQuill
            id="about-university"
            theme="snow"
            value={universityData.about_us}
            onChange={(value) => setState((prevState) => ({ ...prevState, about_us: value }))}
            className="bg-gray-800 text-white border-gray-600"
          />
        </div>

        {/* Media Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-2 text-sm font-medium">Logo</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, setLogoFile)}
              className="w-full p-2 border rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium">Background Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, setBackgroundFile)}
              className="w-full p-2 border rounded bg-gray-700 text-white"
            />
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <MapPinned className="mr-2 text-blue-400" />
            Course Locations
          </h3>
          {universityData.course_locations.map((location, index) => (
            <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 p-4 border border-gray-600 rounded relative">
              <button
                type="button"
                onClick={() => deleteNestedField(index)}
                className="absolute -top-2 -right-2 text-red-500 hover:text-red-400"
              >
                <Trash2 size={20} />
              </button>
              <InputField
                icon={Building2}
                label="Location Name"
                type="text"
                name="location_name"
                placeholder="Location Name"
                value={location.location_name}
                className="bg-gray-700"
                onChange={(e) => handleNestedChange(index, "location_name", e)}
              />
              <InputField
                icon={MapPin}
                label="Location Address"
                type="text"
                name="location_address"
                placeholder="Location Address"
                value={location.location_address}
                onChange={(e) => handleNestedChange(index, "location_address", e)}
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addNestedField}
            className="bg-blue-600 hover:bg-blue-500 text-white py-2 px-4 rounded flex items-center"
          >
            <Plus className="mr-2" /> Add Location
          </button>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-500 text-white py-3 rounded-lg flex items-center justify-center"
          disabled={isLoading}
        >
          {isLoading ? 'Saving...' : <><Save className="mr-2" /> {isEditMode ? 'Update University Information' : 'Save University Information'}</>}
        </button>
      </form>
    </div>
  );
};

export default UploadUniversityInfo;