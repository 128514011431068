import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import toast from "react-hot-toast";
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import Dashboard from "./components/Admin/admin-dashboard/adminDashboard";
import Overview from "./components/Admin/overview/Overview";
// import LoginPage from "./components/Home/pages/LoginPage";
import FilterEventsPage from './components/Student/Events/FilterEventsPage'
import { gsap } from "gsap";
import CollegeUpload from "./components/Admin/universities/CollgeUpload";
import UserDashboard from "./components/Student/userdashboard/UserDashboard";
import EventsPage from "./components/Student/Events/EventsPage";
import University from "./components/Student/Universities/University";
import StudentProfile from "./components/Student/userProfile/StudentProfile";
// import UniversityDetails from "./components/Student/Universities/UniversityDetails";
import JobsHome from "./components/Admin/jobs/JobsHome";
import PrivateRoute from "./auth/PrivateRoute/PrivateRoute";
import PageNotFound from "./components/Home/pages/PageNotFound";
import NewHome from "./components/Home/pages/NewHome"
// import Join from "./components/Home/pages/Join";
// import SmeForm from './components/Home/pages/SmeForm';
// import Jobs from "./components/Student/Jobs/Jobs";
import TempJobs from "./components/Student/Jobs/TempJobs";
import AboutUsPage from "./components/Home/pages/AboutUsPage";
import IQAgentMiddleware from "./components/Student/Accomodation/Accomodation";
// import AuthComponent from "./components/Testing";
import DownloadExcel from "./components/Admin/FetchData";
import AddRestaurant from "./components/Admin/restaurants/AddRestaurant";
import Restaurant from "./components/Student/Explore/RestaurantTabs";
import SwipeCarousel from "./components/Student/Explore/SwipeCarousel";
import Mentorship from "./components/Home/pages/Mentorship";
import UploadEventForm from "./components/Admin/events/UploadEvents";
import EventPage from "./components/Student/Events/EventPage";
import Success from "./components/CheckOutForm/Success";
import Failure from "./components/CheckOutForm/Failure";
// import Maps from "./components/Student/Maps/Maps";
import MyBookings from "./components/Student/Bookings/MyBookings";
import Ambassador from "./components/Home/pages/Ambassador";
// import GoogleMap from "./components/Student/Maps/GoogleMaps";
import CheckoutDetails from "./components/Admin/events/Bookings";
// import MapComponent from "./components/Student/Maps/GoogleMaps";
// import Home from "./components/Home/pages/Home";
import QRScanner from "./components/Admin/events/TicketVerifiction";
import VerificationLogs from "./components/Admin/events/VerificationLogs";
// import MapWithGeocoding from "./components/Student/Maps/GeoCode";
// import CityRestaurants from "./components/Student/Explore/CityRestaurants";
import Layout from "./components/Home/layout/layout";
import TicektPreview from "./components/Student/Bookings/TicketPreview";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import RefundRequests from "./components/Admin/events/RefundRequests";
import BusinessHome from "./components/Home/pages/BusinessHome";
import BusinessLoginPage from "./components/Home/pages/BusinessLoginPage";
// import OrgPage from "./components/Admin/events/BusinessEventCompanyPage";
import EditPageSettings from "./components/Admin/events/EditPageSetting";
import BusinessEventCompanyPage from "./components/Admin/events/BusinessEventCompanyPage";
import PublicEventPage from "./components/Home/pages/PublicEventPage";
// import Feed from "./components/Student/Explore/Reliv/Feed";
// import Post from "./components/Reliv/post";
// import MyProfile from "./components/Reliv/MyProfile";
// import AddPost from "./components/Reliv/AddPost";
import RelivHome from "./components/Reliv/RelivHome";
import PreviewUniversity from "./components/Student/Universities/PreviewUniversity";
import RestaurantPage from "./components/Student/Explore/RestaurantPage";
import RestaurantGrid from "./components/Student/Explore/CityBasedRestaurants";
import ExploreMain from "./components/Student/Explore/ExploreMain";
import AddExperience from "./components/Reliv/AddExperience";
import Itinerary from "./components/Reliv/Itinerary";
import PostReliv from "./components/Reliv/PostReliv";
import RelivAndBooking from "./components/Reliv/RelivAndBooking";
import MyWorlds from "./components/Reliv/MyWorlds";
import RelivAnalyticsDashboard from "./components/Reliv/RelivAnalyticsDashboard";
import EditProfile from "./components/Reliv/EditProfileModal";
import WeatherDashboard from "./components/Home/pages/WeatherDashboard";
import Login_Test from './components/Home/pages/Login_Test';
import RoomDetails from "./components/Student/Accomodation/RoomDetails"
import ExperienceCart from "./components/Reliv/ExperienceCart";
import Unauthorized from "./auth/PrivateRoute/Unauthorized";
import { useAuth } from "./auth/userProvider/AuthProvider";
import { JobsProvider } from './components/Admin/jobs/JobsContext';
import PostJobs from './components/Admin/jobs/PostJobs';
import JobListing from './components/Admin/jobs/JobListing';
import JobsDashboard from "./components/Admin/jobs/JobsDashboard";
import CompanyPage from "./components/Admin/jobs/CompanyPage";
import AccommodationForm from "./components/Admin/accomodation/AccommodationHome";
import PostAccomodation from "./components/Admin/accomodation/PostAccommodation";
import AccommodationHome from "./components/Admin/accomodation/AccommodationHome";
import AvailableRooms from "./components/Admin/accomodation/AvailableRooms";
import UniversityLayout from "./components/Admin/universities/UniversityLayout";
import UploadUniversityInfo from "./components/Admin/universities/UploadUniversityInfo";
import AddCourses from "./components/Admin/universities/AddCourses";
import UniversityDashboard from "./components/Admin/universities/UniversityDashboard";
gsap.registerPlugin();

function App() {
  const { toastMessage, updateToastMessage } = useAuth();

  useEffect(() => {
    if (toastMessage) {
      toast(toastMessage.message, {
        type: toastMessage.type,
        position: "top-right",
        autoClose: 1200,
        theme: "dark",
      });
      updateToastMessage(null);
    }
  }, [toastMessage, updateToastMessage]);

  return (
    <Router>

      <div className="App ">
        <ToastContainer />

        <JobsProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/ambassador" element={<Ambassador />} />
              <Route path="/" element={<NewHome />} />
              {/* <Route path="/home" element={<Home />} /> */}

              {/* <Route path="/geo" element={<MapWithGeocoding />} /> */}
              {/* <Route path="/form" element={<QuizApp />} /> */}
              {/* <Route path="/details" element={<DetailsPage />} /> */}
              <Route path="/login" element={<Login_Test />} />
              {/* <Route path="/login_test" element={<Login_Test />} /> */}
              {/* <Route path="/testing" element={<AuthComponent />} /> */}
              {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
              <Route path="/unauthorized" element={<Unauthorized />} />

              <Route path="/blank" element={<PageNotFound />} />
              {/* <Route path="/join-waiting-list" element={<Join />} /> */}
              <Route path="/mentorship" element={<Mentorship />} />
              {/* <Route path="/write-to-us" element={<SmeForm />} /> */}
              <Route path="/about-us" element={<AboutUsPage />} />
              {/* <Route path="/maps" element={<Maps />} />
              <Route path="/google" element={<MapComponent />} /> */}
              <Route path="/weather" element={<WeatherDashboard />} />

              <Route path="/events" element={<EventsPage />} />
              <Route path="/job-opportunities" element={<TempJobs />} />
              <Route path="/accommodation" element={<IQAgentMiddleware />} />
              <Route path="/universities" element={<University />} />
              <Route path="/universities/:id" element={<PreviewUniversity />} />
              <Route path="/events/p/:companyPage" element={<PublicEventPage />} />

              <Route path="/events/eventDetails/:eventId" element={<EventPage />} />
              <Route
                path="/events/all-events/:eventCategory"
                role="student"
                element={<FilterEventsPage />}
              />
              <Route
                path="/events/all-events"
                role="student"
                element={<FilterEventsPage />}
              />
            </Route>

            <Route path="/business" element={<BusinessHome />} />
            <Route path="/business/signup" element={<BusinessLoginPage />} />

            <Route exact path="/" element={<PrivateRoute role="admin" />}>
              <Route path="/business" role="admin" element={<Dashboard />}>
                <Route
                  path="/business/events/overview"
                  role="admin"
                  element={<Overview />}
                />
                <Route
                  path="/business/events"
                  role="admin"
                  element={<BusinessEventCompanyPage />}
                />
                <Route
                  path="/business/events/new"
                  role="admin"
                  element={<UploadEventForm />}
                />
                <Route
                  path="/business/events/edit/:eventId"
                  role="admin"
                  element={<UploadEventForm />}
                />
                <Route
                  path="/business/events/company-profile"
                  role="admin"
                  element={<EditPageSettings />}
                />
                <Route
                  path="/business/events/verify"
                  role="admin"
                  element={<QRScanner />}
                />
                <Route
                  path="/business/events/verificaton-logs"
                  role="admin"
                  element={<VerificationLogs />}
                />
                <Route
                  path="/business/events/orders"
                  role="admin"
                  element={<CheckoutDetails />}
                />
                <Route
                  path="/business/events/requests"
                  role="admin"
                  element={<RefundRequests />}
                />
                <Route
                  path="/business/add-college"
                  role="admin"
                  element={<CollegeUpload />}
                />
                <Route
                  path="/business/restaurants"
                  role="admin"
                  element={<AddRestaurant />}
                />
                <Route
                  path="/business/university"
                  role="admin"
                  element={<UniversityLayout />}
                >
                  <Route path="upload/info" element={<UploadUniversityInfo />} />
                  <Route path="add/courses" element={<AddCourses />} />
                  <Route path="dashboard" element={<UniversityDashboard />} />
                </Route>
                
                <Route
                  path="/business/jobs"
                  role="admin"
                  element={<JobsHome />}
                >
                  <Route path="dashboard" element={<JobsDashboard />} />
                  <Route path="add-job" element={<PostJobs />} />
                  <Route path="job-listings" element={<JobListing />} />
                  <Route path="profile" element={<CompanyPage />} />
                  {/* <Route path="settings" element={<Settings />} /> */}
                </Route>
                <Route
                  path="/business/accommodation"
                  role="admin"
                  element={<AccommodationHome />}
                >
                  {/* <Route path="dashboard" element={<JobsDashboard />} /> */}
                  <Route path="add" element={<PostAccomodation />} />
                  <Route path="rooms" element={<AvailableRooms />} />
                  {/* <Route path="profile" element={<CompanyPage />} /> */}
                  {/* <Route path="settings" element={<Settings />} /> */}
                </Route>
                <Route path="/business/accommodation" role="admin" element={<PostAccomodation />} />

              </Route>


            </Route>

            <Route path="/" element={<PrivateRoute role="student" />}>
              <Route
                path="/user-dashboard"
                role="student"
                element={<UserDashboard />}
              >
                <Route
                  path="/user-dashboard/events"
                  role="student"
                  element={<EventsPage />}
                />

                <Route
                  path="/user-dashboard/success?session_id={CHECKOUT_SESSION_ID}"
                  role="student"
                  element={<Success />}
                />
                <Route
                  path="/user-dashboard/success"
                  role="student"
                  element={<Success />}
                />
                <Route
                  path="/user-dashboard/failure"
                  role="student"
                  element={<Failure />}
                />
                <Route
                  path="/user-dashboard/fetchData"
                  role="student"
                  element={<DownloadExcel />}
                />
                <Route
                  path="/user-dashboard/swipe"
                  role="student"
                  element={<SwipeCarousel />}
                />
                <Route
                  path="/user-dashboard/bookings/tickets/:ticketId"
                  role="student"
                  element={<TicektPreview />}
                />


                <Route
                  path="/user-dashboard/events/all-events/:eventCategory"
                  role="student"
                  element={<FilterEventsPage />}
                />
                <Route
                  path="/user-dashboard/events/all-events"
                  role="student"
                  element={<FilterEventsPage />}
                />
                {/* <Route
                path="/user-dashboard/events/all-events/:eventCategory/:eventId"
                role="student"
                element={<EventDetails />}
              /> */}

                <Route
                  path="/user-dashboard/eventDetails/:eventId"
                  element={<EventPage />}
                />
                <Route
                  path="/user-dashboard/accommodation"
                  role="student"
                  element={<IQAgentMiddleware />}
                />
                <Route
                  path="/user-dashboard/university"
                  role="student"
                  element={<University />}
                />
                <Route
                  path="/user-dashboard/jobs"
                  role="student"
                  element={<TempJobs />}
                />
                {/* <Route
                path="/user-dashboard/university/:universityId"
                role="student"
                element={<UniversityDetails />}
              /> */}
                <Route
                  path="/user-dashboard/universities/:id"
                  role="student"
                  element={<PreviewUniversity />}
                />
                <Route
                  path="/user-dashboard/explore"
                  role="student"
                  element={<ExploreMain />}
                />
                <Route
                  path="/user-dashboard/accommodation/:accommodationId"
                  role="student"
                  element={<RoomDetails />}
                />
                <Route
                  path="/user-dashboard/restaurant/:uuid"
                  role="student"
                  element={<RestaurantPage />}
                />
                <Route
                  path="/user-dashboard/reliv"
                  role="student"
                  element={<RelivHome />}
                />
                <Route
                  path="/user-dashboard/reliv/create/post"
                  role="student"
                  element={<PostReliv />}
                />
                <Route
                  path="/user-dashboard/reliv/create/itinerary/:experienceId"
                  role="student"
                  element={<Itinerary />}
                />
                {/* <Route
                path="/user-dashboard/reliv/create/post/:experienceId"
                role="student"
                element={<PostReliv />}
              /> */}
                <Route path="/user-dashboard/reliv/book/:postId" element={<RelivAndBooking />} />
                <Route path="/user-dashboard/reliv/book/cart" element={<ExperienceCart />} />

                <Route
                  path="/user-dashboard/reliv/myworlds"
                  role="student"
                  element={<MyWorlds />}
                />
                <Route
                  path="/user-dashboard/profile"
                  role="student"
                  element={<EditProfile />}
                />
                <Route
                  path="/user-dashboard/reliv/analysis"
                  role="student"
                  element={<RelivAnalyticsDashboard />}
                />
                {/* <Route
                path="/user-dashboard/reliv/:category/:categoryName"
                role="student"
                element={<Feed />}
              /> */}

                {/* <Route
                path="/user-dashboard/explore/post/:postId"
                role="student"
                element={<Post />}
              /> */}
                {/* <Route
                path="/user-dashboard/explore/my-profile"
                role="student"
                element={<MyProfile />}
              /> */}
                {/* <Route
                path="/user-dashboard/reliv/upload"
                role="student"
                element={<AddPost />}
              /> */}

                <Route
                  path="/user-dashboard/explore/:restaurantId"
                  role="student"
                  element={<Restaurant />}
                />
                <Route
                  path="/user-dashboard/explore/c/:city"
                  role="student"
                  element={<RestaurantGrid />}
                />
                {/* <Route
                path="/user-dashboard/explore/c/:city"
                role="student"
                element={<CityRestaurants />}
              /> */}
                <Route
                  path="/user-dashboard/:city/:restaurantId"
                  role="student"
                  element={<Restaurant />}
                />
                <Route
                  path="/user-dashboard/bookings"
                  role="student"
                  element={<MyBookings />}
                />
                <Route
                  path="/user-dashboard/settings/:userId"
                  role="student"
                  element={<StudentProfile />}
                />
              </Route>
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </JobsProvider>
      </div>
    </Router>
  );
}

export default App;