import React, { useState } from "react";
import { Briefcase, MapPin, DollarSign, Navigation, Clock, Tag, Image, Plus, X, Send, HousePlus } from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { storage, firestore } from "../../../firebase";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore"; // Import updateDoc and doc
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { toast, Toaster } from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

const PostAccommodation = () => {
  const [formData, setFormData] = useState({
    roomName: "",
    city: "",
    location: "",
    cost: "",
    distance: "",
    walkingDistance: "",
    trainDistance: "",
    drivingDistance: "",
    tags: [],
    offersAndDetails: [],
    description: "",
    room_types: [], // Changed from roomTypes to room_types
    images: [],
  });

  const [currentRoomType, setCurrentRoomType] = useState({
    room_type_name: "",
    starting_price: "",
    availability_date: "",
    features: []
  });

  const [newTag, setNewTag] = useState("");
  const [newOfferAndDetails, setOffersAndDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleAddTag = () => {
    if (newTag.trim() && !formData.tags.includes(newTag.trim())) {
      setFormData({ ...formData, tags: [...formData.tags, newTag.trim()] });
      setNewTag("");
    }
  };
  const handleOffersAndDetails = () => {
    if (newOfferAndDetails.trim() && !formData.offersAndDetails.includes(newOfferAndDetails.trim())) {
      setFormData({ ...formData, offersAndDetails: [...formData.offersAndDetails, newOfferAndDetails.trim()] });
      setOffersAndDetails("");
    }
  };
  const handleRemoveOffersAndDetails = (offerToRemove) => {
    setFormData({
      ...formData,
      offersAndDetails: formData.offersAndDetails.filter((offer) => offer !== offerToRemove),
    });
  };

  const handleRemoveTag = (tagToRemove) => {
    setFormData({
      ...formData,
      tags: formData.tags.filter((tag) => tag !== tagToRemove),
    });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `accommodation/${userData.userId}/${userData.companyName}${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setFormData({ ...formData, images: [...formData.images, url] });
    }
  };

  const handleRoomTypeChange = (e) => {
    const { name, value } = e.target;
    setCurrentRoomType(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFeaturesChange = (e) => {
    const features = e.target.value.split(',').map(f => f.trim());
    setCurrentRoomType(prev => ({
      ...prev,
      features
    }));
  };

  const handleAddRoomType = () => {
    if (currentRoomType.room_type_name) {
      setFormData(prev => ({
        ...prev,
        room_types: [...prev.room_types, currentRoomType]
      }));
      setCurrentRoomType({
        room_type_name: "",
        starting_price: "",
        availability_date: "",
        features: []
      });
    }
  };

  const handleRemoveRoomType = (index) => {
    setFormData(prev => ({
      ...prev,
      room_types: prev.room_types.filter((_, i) => i !== index)
    }));
  };

  const resetForm = () => {
    setFormData({
      roomName: "",
      city: "",
      location: "",
      cost: "",
      distance: "",
      walkingDistance: "",
      trainDistance: "",
      drivingDistance: "",
      tags: [],
      offersAndDetails: [],
      description: "",
      room_types: [],
      images: [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!userData || !userData.userId) {
        throw new Error("User ID is undefined");
      }
      const accommodationData = {
        ...formData,
        createdBy: userData.userId,
        createdAt: new Date(),
        userId: userData.userId // Attach userId here
      };
      const docRef = await addDoc(collection(firestore, "accommodations"), accommodationData);
      const accommodationId = docRef.id;
      await updateDoc(doc(firestore, "accommodations", accommodationId), { accommodationId }); // Use updateDoc
      toast.success("Accommodation posted successfully");
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      toast.error("Error posting accommodation");
      console.error("Error posting accommodation:", error);
    }
    setLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <Toaster position="top-right" toastOptions={{ style: { background: "#333", color: "#fff" } }} />
      <h2 className="text-3xl font-bold mb-6 flex items-center">
        <HousePlus className="mr-3 text-blue-400" />
        Add Accommodation Post
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Room Name and City */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-2 text-sm font-medium">Room Name</label>
            <input
              type="text"
              name="roomName"
              value={formData.roomName}
              onChange={handleChange}
              placeholder="Deluxe Suite"
              className="w-full p-3 bg-gray-700 text-white rounded"
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium">City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              placeholder="New York"
              className="w-full p-3 bg-gray-700 text-white rounded"
            />
          </div>
        </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block mb-2 text-sm font-medium">Location</label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Downtown"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">Cost</label>
              <input
                type="number"
                name="cost"
                value={formData.cost}
                onChange={handleChange}
                placeholder="1000"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">Distance (in km)</label>
              <input
                type="number"
                name="distance"
                value={formData.distance}
                onChange={handleChange}
                placeholder="5"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
            </div>
          </div>

          {/* Distance Fields */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block mb-2 text-sm font-medium">Walking Distance (in mins)</label>
              <input
                type="number"
                name="walkingDistance"
                value={formData.walkingDistance}
                onChange={handleChange}
                placeholder="10"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">Train Distance (in mins)</label>
              <input
                type="number"
                name="trainDistance"
                value={formData.trainDistance}
                onChange={handleChange}
                placeholder="15"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">Driving Distance (in mins)</label>
              <input
                type="number"
                name="drivingDistance"
                value={formData.drivingDistance}
                onChange={handleChange}
                placeholder="20"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
            </div>
          </div>

      

          <div>
            <label className="block mb-2 text-sm font-medium">Description</label>
            <ReactQuill
              theme="snow"
              value={formData.description}
              onChange={handleDescriptionChange}
              className="bg-gray-800 text-white border-gray-600"
            />
          </div>

          {/* Offers and Details */}
          <div>
          <label className="block mb-2 text-sm font-medium">Offers and Details</label>
          <div className="flex">
            <input
              type="text"
              value={newOfferAndDetails}
              onChange={(e) => setOffersAndDetails(e.target.value)}
              placeholder="Add tags (e.g., Pool, WiFi)"
              className="flex-grow p-3 bg-gray-700 text-white rounded-l"
            />
            <button
              type="button"
              onClick={handleOffersAndDetails}
              className="bg-blue-600 px-4 rounded-r hover:bg-blue-500"
            >
              <Plus />
            </button>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {formData.offersAndDetails.map((offer, index) => (
              <div
                key={`${offer}-${index}`}
                className="flex items-center bg-blue-600 text-white text-sm px-2 py-1 rounded"
              >
                {offer}
                <button onClick={() => handleRemoveOffersAndDetails(offer)} className="ml-2">
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">Add Tags</label>
          <div className="flex">
            <input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="Add tags (e.g., Pool, WiFi)"
              className="flex-grow p-3 bg-gray-700 text-white rounded-l"
            />
            <button
              type="button"
              onClick={handleAddTag}
              className="bg-blue-600 px-4 rounded-r hover:bg-blue-500"
            >
              <Plus />
            </button>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {formData.tags.map((tag, index) => (
              <div
                key={`${tag}-${index}`}
                className="flex items-center bg-blue-600 text-white text-sm px-2 py-1 rounded"
              >
                {tag}
                <button onClick={() => handleRemoveTag(tag)} className="ml-2">
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>


        <div className="space-y-4">
            <label className="block mb-2 text-sm font-medium">Room Types</label>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 bg-gray-800 rounded">
              <input
                type="text"
                name="room_type_name"
                value={currentRoomType.room_type_name}
                onChange={handleRoomTypeChange}
                placeholder="Room Type Name"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
              <input
                type="text"
                name="starting_price"
                value={currentRoomType.starting_price}
                onChange={handleRoomTypeChange}
                placeholder="Price (e.g., £414/week)"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
              <input
                type="text"
                name="availability_date"
                value={currentRoomType.availability_date}
                onChange={handleRoomTypeChange}
                placeholder="Availability Date"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
              <input
                type="text"
                value={currentRoomType.features.join(", ")}
                onChange={handleFeaturesChange}
                placeholder="Features (comma-separated)"
                className="w-full p-3 bg-gray-700 text-white rounded"
              />
              <button
                type="button"
                onClick={handleAddRoomType}
                className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-500 col-span-2"
              >
                Add Room Type
              </button>
            </div>

            <div className="space-y-2">
              {formData.room_types.map((room, index) => (
                <div
            key={index}
            className="bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-all cursor-pointer overflow-hidden relative"
                >
            <div className="flex flex-col md:flex-row">
              {/* Image Section */}
              <div className="w-full md:w-1/4 h-48 md:h-auto bg-gray-700 flex items-center justify-center">
                <img
                  src={room.image_url || "https://ik.imgkit.net/3vlqs5axxjf/TW/ik-seo/dist/images/no-image/Hotel-dell%27Ancora.jpg?tr=w-360%2Ch-216%2Cfo-auto"}
                  alt={room.room_type_name}
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Details Section */}
              <div className="flex-1 p-6">
                <h4 className="text-lg font-semibold text-white mb-2">
                  {room.room_type_name}
                </h4>
                <p className="text-green-400 font-medium mb-2">
                  {room.availability_date}
                </p>
                <p className="text-gray-400 mb-2">
                  Starting Price:
                  <span className="ms-1 font-semibold text-gray-300">
              {room.starting_price}
                  </span>
                </p>

                {/* Features as Tags */}
                <div className="flex flex-wrap items-center gap-2">
                  {room.features.map((feature, idx) => (
              <span
                key={idx}
                className="bg-gray-700 text-gray-300 py-1 px-3 rounded-full text-sm"
              >
                {feature}
              </span>
                  ))}
                </div>
              </div>
            </div>
            <button
              onClick={() => handleRemoveRoomType(index)}
              className="absolute top-2 right-2 bg-red-600 text-white p-1 rounded-full"
            >
              <X size={16} />
            </button>
                </div>
              ))}
            </div>
          </div>
        {/* Images */}
        <div>
          <label className="block mb-2 text-sm font-medium">Upload Images</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="w-full p-3 bg-gray-700 text-white rounded"
          />
          <div className="mt-2 flex flex-wrap gap-2">
            {formData.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Upload ${index}`}
                className="w-24 h-24 object-cover rounded"
              />
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-500 text-white py-3 rounded-lg flex items-center justify-center"
          disabled={loading}
        >
          {loading ? <FaSpinner className="animate-spin mr-2" /> : <Send className="mr-2" />} Submit
          Accommodation Post
        </button>
      </form>
    </div>
  );
};

export default PostAccommodation;
