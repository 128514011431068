import React, { useState, useEffect, useMemo } from 'react';
import { room_list } from './room_list';
import room from '../../../assets/img/room.jpg';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { firestore } from '../../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { FaHome, FaHouseUser } from 'react-icons/fa';

const HotelBooking = () => {
  const [exportData, setExportData] = useState([]);
  const [filterCity, setFilterCity] = useState(''); // Default to all cities
  const [searchTerm, setSearchTerm] = useState('');
  const [uniqueCities, setUniqueCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState(300);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const itemsPerPage = 8;
  const [firebaseRooms, setFirebaseRooms] = useState([]);

  // Single useEffect for Firebase data fetching
  useEffect(() => {
    const fetchFirebaseRooms = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'accommodations'));
        const rooms = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          accommodationId: doc.id,
          roomName: doc.data().title || doc.data().roomName,
          tags: [
            ...(doc.data().amenities || []),
            `£${doc.data().price}/month`
          ],
          images: doc.data().images || [],
          searchText: `${doc.data().title || doc.data().roomName || ''} ${doc.data().city || ''} ${doc.data().location || ''}`.toLowerCase()
        }));
        setFirebaseRooms(rooms);
      } catch (error) {
        console.error('Error fetching Firebase rooms:', error);
        setFirebaseRooms([]);
      }
    };
    fetchFirebaseRooms();
  }, []);

  // Use useMemo to combine and filter all data
  const filteredData = useMemo(() => {
    // Combine Firebase and local data, with Firebase data first
    const allRooms = [...firebaseRooms, ...room_list].map(room => ({
      ...room,
      searchText: `${room.roomName || room.title || ''} ${room.city || ''} ${room.location || ''} ${(room.tags || []).join(' ')}`.toLowerCase()
    }));

    // Apply all filters
    return allRooms.filter((room) => {
      if (!room) return false;

      // Search filter
      const matchesSearch = !searchTerm ||
        room.searchText.includes(searchTerm.toLowerCase());

      // City filter
      const matchesCity = !filterCity ||
        room.city?.toLowerCase().trim() === filterCity.toLowerCase().trim();

      // Price filter
      let price;
      if (room.price) {
        price = parseInt(room.price);
      } else if (room.tags) {
        const priceString = room.tags[room.tags.length - 1];
        const priceMatch = priceString.match(/\d+/);
        price = priceMatch ? parseInt(priceMatch[0]) : null;
      }

      const matchesPrice = !price ||
        ((!minPrice || price >= parseInt(minPrice)) &&
          (!maxPrice || price <= parseInt(maxPrice)));

      return matchesSearch && matchesCity && matchesPrice;
    });
  }, [firebaseRooms, searchTerm, filterCity, minPrice, maxPrice]);

  // Update unique cities based on filtered data
  useEffect(() => {
    const cities = [...new Set(filteredData
      .map(item => item?.city?.trim())
      .filter(Boolean)
    )];
    setUniqueCities(cities);
  }, [filteredData]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handleBuyClick = (roomLink) => {
    window.open(roomLink, '_blank');
  };
  const handleViewDetails = (accommodationId) => {
    if (currentUser) {
      navigate(`/user-dashboard/accommodation/${accommodationId}`);
    } else {
      //console.log("login");
      navigate('/login');
    }
  };
  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      {/* Hero Section with Banner Image */}
      <div className="relative bg-gray-800 py-12 rounded-4 mx-2 bg-cover bg-center" style={{ backgroundImage: `url(${room})` }}>
        <div className="absolute top-4 right-4 z-20">
          <button className="bg-orange-600 text-white px-3 py-2 rounded-4 shadow flex items-center gap-2 hover:bg-orange-700 transition duration-200"
            onClick={() => navigate('/business/signup')}>
            <FaHouseUser className="text-white" />
            Become a Host
          </button>
        </div>
        <div className="relative z-10 container mx-auto px-4">
          <h1 className="text-4xl sm:text-5xl font-bold text-white mb-4">Find Your Ideal Accommodation</h1>
          <p className="text-xl text-gray-300">Discover comfortable stays tailored to your needs.</p>
        </div>
      </div>

      {/* Filters and Results */}
      <div className="container mx-auto py-8">
        <div className="mb-8">
          <div className="row align-items-center mb-8">
            <div className="col-md-9 col-sm-12">
              <div className="flex items-center w-full border-2 border-orange-600 rounded-md bg-transparent">
                <span className="pl-3 text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-4.35-4.35m0 0a7 7 0 111.4-1.4m-1.4 1.4L16.65 16.65z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="bg-transparent text-gray-100 py-2.5 pl-3 pr-3 w-full focus:outline-none"
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <button className="bg-gray-50 text-black rounded-3 font-bold px-4 py-2.5 hover:bg-gray-100 transition duration-200">
                Clear
              </button>
              <button className="ms-4 bg-orange-600 text-white rounded-3 font-bold px-4 py-2.5 hover:bg-orange-700 transition duration-200">
                Search
              </button>
            </div>
          </div>
          <div className="align-items-baseline row">
            <div className="col-4">

              <select
                onChange={e => setFilterCity(e.target.value)}
                className="bg-white font-bold text-black p-2.5 rounded-3 w-full"
              >
                <option value="">Search by city</option>
                {uniqueCities.map((city, index) => (
                  <option key={index} value={city}>{city}</option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <div className="flex">
                <div>
                  <label>Price Range:</label>
                </div>
                <div className="w-60 ms-2">
                  <div className="relative">
                    <input
                      type="range"
                      id="maxPrice"
                      min={100}
                      max={2000}
                      step={10}
                      value={maxPrice}
                      onChange={e => setMaxPrice(e.target.value)}
                      className="w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer accent-orange-500"
                      style={{
                        background: `linear-gradient(to right, #FF4500 0%, #FF4500 ${(maxPrice - 100) / (2000 - 100) * 100}%, #E5E5E5 ${(maxPrice - 100) / (2000 - 100) * 100}%, #E5E5E5 100%)`,
                      }}
                    />
                    <div
                      className="absolute top-[-32px] left-1/2 transform -translate-x-1/2 bg-white text-orange-600 px-3 py-1 rounded-5 shadow-md text-sm"
                      style={{
                        left: `calc(${(maxPrice - 100) / (2000 - 100) * 100}% - 20px)`,
                      }}
                    >
                      £{maxPrice}
                    </div>
                  </div>
                  <div className="flex justify-between text-gray-400 text-sm">
                    <span>£100</span>
                    <span>£2000</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Hotel Cards */}
        {filteredData.length === 0 ? (
          <div className="text-center text-gray-400 mt-8">
            <p>No rooms available matching your criteria.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-6">
            {currentItems.map((item, index) => {
              // Safely extract price and tags
              const tags = item?.tags || [];
              const price = tags.length > 0 ? tags[tags.length - 1] : 'Price not available';
              const detailsWithoutPrice = tags.length > 0 ? tags.slice(0, -1) : [];
              const images = item?.images || [];

              return (
                <div
                  key={index}
                  className="bg-transparent border-2 p-2.5 rounded-lg overflow-hidden transition-all transform flex flex-col sm:flex-row"
                >
                  {/* Slider Section */}
                  <div className="w-full sm:w-1/3 slider_arrows">
                    <Slider
                      dots={false}
                      infinite={true}
                      speed={500}
                      slidesToShow={1}
                      slidesToScroll={1}
                      arrows={true}
                      className="group relative"
                    >
                      {images.map((image, idx) => (
                        <img
                          key={idx}
                          src={image}
                          alt={`Accommodation Image ${idx + 1}`}
                          className="w-full h-15.7 object-cover rounded-lg"
                        />
                      ))}
                    </Slider>
                  </div>

                  {/* Content Section */}
                  <div className="px-4 sm:w-2/3">
                    <div className="flex items-center justify-between">
                      <h2 className="text-2xl font-semibold mb-0">{item.roomName}</h2>
                      <p className="text-2xl font-bold text-orange-600 m-0">{price}</p>
                    </div>
                    <p className="text-sm text-gray-400 mb-0">{item.location}</p>
                    <p className="text-sm text-gray-400 mb-2">{item.city}</p>
                    <div className="flex flex-wrap gap-2 mb-4">
                      {detailsWithoutPrice.map((detail, idx) => (
                        <span
                          key={idx}
                          className="bg-gray-700 text-gray-300 text-xs p-2 rounded-2"
                        >
                          {detail}
                        </span>
                      ))}
                    </div>
                    {/* Price Section */}
                    <div className="text-end mt-4">
                      <button
                        className="bg-gray-50 text-orange-600 py-2 px-4 font-bold rounded-4 hover:bg-gray-200 transition duration-300"
                        onClick={() => handleViewDetails(item.accommodationId)}
                      >
                        Click to view more
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Pagination */}
        <div className="flex justify-center items-center space-x-4 mt-8">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
          >
            Previous
          </button>
          <span className="text-gray-400">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default HotelBooking;
